import React, { useEffect, useState } from 'react'
import Banner from '../Banner/Banner'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { RankTable } from './RankTable'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'
import { Interweave } from 'interweave'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-duotone-svg-icons'
import { colors } from '@theme'
import { TournamentFileContent } from '@alienclient/back-front'
import { fetchJSONData } from 'helpers/helpers'
import { useTranslation } from 'react-i18next'

const TournamentHistory = () => {
  const { tournamentSection } = useAppSelector(getUi)
  const [stats, setStats] = useState<TournamentFileContent>()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const statsList = tournamentSection.history?.statsList
  const selectedStatsURL =
    tournamentSection.history?.statsList?.[selectedIndex]?.statsURL

  async function fetchData() {
    try {
      if (selectedStatsURL !== null && selectedStatsURL !== undefined) {
        // Timestamp
        const jsonData = await fetchJSONData(
          `${selectedStatsURL}?ts=${Date.now().toString().slice(0, -4)}`
        )
        if (setStats) {
          setStats(jsonData)
        }
      }
      // Use the fetched JSON data here
    } catch (error) {
      // console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [tournamentSection])

  useEffect(() => {
    fetchData()
  }, [selectedIndex])

  if (!tournamentSection) return <></>

  return (
    <Banner
      sx={{
        p: 1.5,

        background: `linear-gradient(${
          dir === 'rtl' ? 290 : 78
        }deg, #FFB423 -17.44%, rgba(255, 164, 30, 0.86) -11.76%, rgba(255, 61, 0, 0.00) 23.29%, rgba(255, 61, 0, 0.00) 53.66%), var(--i-moon-gray-3, #1F1933)`,
      }}
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'center', md: 'initial' },
          justifyContent: 'space-between',
          gap: 3,
        }}
      >
        <Content
          selectedIndex={selectedIndex}
          statsList={statsList}
          prevClick={() => {
            selectedIndex + 1 !== statsList?.length &&
              setSelectedIndex((prev) => prev + 1)
          }}
          nextClick={() => {
            selectedIndex !== 0 && setSelectedIndex((prev) => prev - 1)
          }}
        />
        <Box sx={{ width: 1 }}>
          <RankTable stats={stats} rankStyle='flat' />
        </Box>
      </Stack>
    </Banner>
  )
}

interface ContentProps {
  selectedIndex: number
  statsList?: {
    name?: string | undefined
    statsURL?: string | undefined
  }[]
  prevClick: () => void
  nextClick: () => void
}

const Content = (props: ContentProps) => {
  const { statsList, nextClick, prevClick, selectedIndex } = props
  const {
    tournamentSection: { history },
  } = useAppSelector(getUi)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  return (
    <Stack sx={{ gap: 2, width: 1, py: 1, maxWidth: [300, 365] }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1.25,
          '.MuiIconButton-root': {
            border: `1px solid ${colors.gray[6]}`,
            width: 40,
            height: 40,
          },
          '.Mui-disabled': {
            opacity: 0.1,
          },
          svg: { color: colors.gray[12], fontSize: 16 },
        }}
      >
        <IconButton
          onClick={(e) => prevClick()}
          disabled={selectedIndex + 1 === statsList?.length}
        >
          <FontAwesomeIcon
            icon={dir === 'rtl' ? faChevronRight : faChevronLeft}
            fixedWidth
          />
        </IconButton>

        <Typography
          variant='headline'
          sx={{ minWidth: 125, textAlign: 'center' }}
        >
          {statsList?.[selectedIndex]?.name}
        </Typography>
        <IconButton onClick={(e) => nextClick()} disabled={selectedIndex === 0}>
          <FontAwesomeIcon
            icon={dir === 'rtl' ? faChevronLeft : faChevronRight}
            fixedWidth
          />
        </IconButton>
      </Box>
      <Interweave content={history?.content} />
      <Box sx={{ px: 2 }}>
        <img src={history?.img?.url} alt='' />
      </Box>
    </Stack>
  )
}

export default TournamentHistory
